<div class="flip-container">
    <div class="flip-card" [ngClass]="{ 'flip-slide': isSlide }">
        <div
            class="front"
            setBackgroundImage
            [imageUrl]="flipCard.FrontBackgroundImage?.ImageUrl ?? ''"
        >
            <div
                class="overlay-text"
                [innerHTML]="flipCard.OverlayText | embed: 'html'"
            ></div>
        </div>

        <div class="back">
            <app-images [Image]="flipCard.BackBackgroundImage"></app-images>
            <div
                class="overlay-text"
                [innerHTML]="flipCard.BackcardText | embed: 'html'"
            ></div>
        </div>
    </div>
</div>
